.nasocir-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 0%;
  margin-top: 0px;
  height: 100%;
  background-color: #c2e266;
}

.diktafont-main {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;
  height: 100%;
}

.diktafont-navigation {
  padding: auto;
  margin-left: 1rem;
}

.diktafont-content {
  margin-top: 1rem;
  width: 70%;
  padding: auto;
}

.main-content-nav {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .main-content-nav {
    flex-direction: column-reverse;
  }
  .diktafont-content {
    margin-top: 1rem;
    width: 100%;
    padding: auto;
  }

  .diktafont-navigation {
    display: none;
    width: 100%;
    margin-left: 0;
  }

  .diktafont-navigation-act {
    display: block;
  }
}
